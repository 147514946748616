.main {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 100%;
}

.form-signin {
  max-width: 530px;
  padding: 15px;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}
